<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent class="py-8" title="IP Thermal Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Recommended Test Setup: SBAT"
              size="medium"
            />
            <a
              :href="require('../../assets/IPSeries/SBATDetailed-min.png')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/IPSeries/SBATDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the in-process thermal test is determine
              the effects of time and temperature on energetic materials in the
              process configuration. Thermal tests generally consist of
              measuring a sample’s response to either: (1) gradually increasing
              the temperature and recording the temperature at which the
              substance gives off heat (autoignition temperature), or (2)
              holding the substance at a given temperature for an extended
              period of time to evaluate stability at a given temperature
              (thermal stability). Both auto-ignition and thermal stability
              tests are used for in-process risk evaluation.
            </p>
          </div>
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              Many different pieces of equipment can be used to obtain an
              autoignition temperature. Some of them are
            </p>
            <ul class="p-light list-disc ml-12">
              <li>Accelerating Rate Calorimetry (ARC),</li>
              <li>
                Simulated Bulk Auto-Ignition Temperature (SBAT) (UN Test Method
                3 (c) (ii)),
              </li>
              <li>Differential Thermal Analysis (DTA), and</li>
              <li>Differential Scanning Calorimetry (DSC).</li>
            </ul>
            <p class="p-light">
              The above pieces of equipment can also be used for thermal
              stability tests. Other pieces of equipment can also be used:
            </p>
            <ul class="p-light list-disc ml-12">
              <li>Taliani,</li>
              <li>Time to explosion (Henkin), and</li>
              <li>Thermal stability in an oven (UN Test Method 3 (c) (i)).</li>
            </ul>
            <p class="p-light mt-2">
              The most conservative auto-ignition or thermal stability result is
              obtained when there are no heat losses from the sample, the sample
              is confined, and the heating rate is very slow. The Accelerated
              Rate Calorimeter (ARC) uses gram sized quantities with high
              insulation, high confinement, with a heat-wait search routine.
              Auto-ignition temperatures from the ARC apparatus are typically
              the lowest. The Simulated Bulk Auto-Ignition test (SBAT) also has
              a high insulation value, gram sized quantities, a slow heating
              rate, and the sample can be confined. The DSC on the other hand
              has a high rate of heat loss and very small samples, although the
              sample can be confined. With these variations between tests, there
              can be significant differences in the auto-ignition and thermal
              stability results. Shown below is a table giving the auto-ignition
              results for multiple substances for the ARC, SBAT, and DSC.
            </p>
            <table class="mt-4 table-auto border p-light">
              <tbody>
                <tr>
                  <th class="border">Substance</th>
                  <th class="border">ARC °C</th>
                  <th class="border">SBAT °C</th>
                  <th class="border">DSC °C</th>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Nitroglycerine sample
                  </td>
                  <td class="border px-4 py-2 font-medium">115</td>
                  <td class="border px-4 py-2 font-medium">124</td>
                  <td class="border px-4 py-2 font-medium">178</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Liquid nitrate ester sample
                  </td>
                  <td class="border px-4 py-2 font-medium">115</td>
                  <td class="border px-4 py-2 font-medium">117</td>
                  <td class="border px-4 py-2 font-medium">171</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Double-base propellant sample
                  </td>
                  <td class="border px-4 py-2 font-medium">120</td>
                  <td class="border px-4 py-2 font-medium">131</td>
                  <td class="border px-4 py-2 font-medium">184</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Sample containing both nitramine and nitrate ester groups
                  </td>
                  <td class="border px-4 py-2 font-medium">125</td>
                  <td class="border px-4 py-2 font-medium">135</td>
                  <td class="border px-4 py-2 font-medium">185</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Nitrate ester propellant sample
                  </td>
                  <td class="border px-4 py-2 font-medium">125</td>
                  <td class="border px-4 py-2 font-medium">136</td>
                  <td class="border px-4 py-2 font-medium">171</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Nitrate ester polyether propellant sample
                  </td>
                  <td class="border px-4 py-2 font-medium">125</td>
                  <td class="border px-4 py-2 font-medium">135</td>
                  <td class="border px-4 py-2 font-medium">169</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Nitrate ester polyether propellant sample
                  </td>
                  <td class="border px-4 py-2 font-medium">126</td>
                  <td class="border px-4 py-2 font-medium">135</td>
                  <td class="border px-4 py-2 font-medium">168</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Organic azide sample
                  </td>
                  <td class="border px-4 py-2 font-medium">150</td>
                  <td class="border px-4 py-2 font-medium">164</td>
                  <td class="border px-4 py-2 font-medium">224</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Aliphatic nitrocompound sample
                  </td>
                  <td class="border px-4 py-2 font-medium">165</td>
                  <td class="border px-4 py-2 font-medium">172</td>
                  <td class="border px-4 py-2 font-medium">225</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    AP composite propellant sample
                  </td>
                  <td class="border px-4 py-2 font-medium">180</td>
                  <td class="border px-4 py-2 font-medium">195</td>
                  <td class="border px-4 py-2 font-medium">248</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">
                    Pyrotechnic sample
                  </td>
                  <td class="border px-4 py-2 font-medium">185</td>
                  <td class="border px-4 py-2 font-medium">184</td>
                  <td class="border px-4 py-2 font-medium">231</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">RDX sample</td>
                  <td class="border px-4 py-2 font-medium">189</td>
                  <td class="border px-4 py-2 font-medium">195</td>
                  <td class="border px-4 py-2 font-medium">218</td>
                </tr>
                <tr>
                  <td class="border px-4 py-2 font-medium">BKNO3 sample</td>
                  <td class="border px-4 py-2 font-medium">277</td>
                  <td class="border px-4 py-2 font-medium">–</td>
                  <td class="border px-4 py-2 font-medium">368</td>
                </tr>
              </tbody>
            </table>
            <p class="p-light mt-2">
              On-average, the SBAT gives auto-ignition temperatures just 9°C
              greater than the ARC. The DSC on the other hand with smaller
              quantities and much greater heat losses gives auto-ignition
              temperatures 50°C greater than the ARC. The
              <router-link class="link" to="/test-equipment/sbat"
                >SBAT</router-link
              >
              has been used by Thiocol, ATK, SMS, and others to obtain
              auto-ignition temperatures and thermal stability results for more
              than 20 years.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th class="px-4 py-2">Key Parameter</th>
                      <th class="px-4 py-2">Objectives</th>
                      <th class="px-4 py-2">Origin</th>
                      <th class="px-4 py-2">Specs</th>
                    </tr>
                    <tr style="background:#f6f6f6;">
                      <td class="border px-4 py-2 font-medium">
                        Temperature profile
                      </td>
                      <td class="border px-4 py-2 font-medium">
                        Slow heating rates (e.g. &lt; 0.5°C/min) allow the
                        sample to respond to the temperature increase; the
                        response of the sample can lag behind when fast heating
                        rates (e.g. 10°C/min) are employed, potentially yielding
                        an artificially elevated auto-ignition temperature.
                        Testing at different isothermal temperatures enables
                        detection of time-dependent behaviors, such as
                        auto-catalytic reactions.
                      </td>
                      <td class="border px-4 py-2 font-medium"></td>
                      <td class="border px-4 py-2 font-medium">
                        SBAT standardized to 12°C/hr
                      </td>
                    </tr>
                    <tr>
                      <td class="border px-4 py-2 font-medium">Heat loss</td>
                      <td class="border px-4 py-2 font-medium">
                        Low heat loss is representative of in-process
                        configurations where heat generated by the sample cannot
                        readily dissipate (insulated); high heat loss is
                        representative of in-process configurations where heat
                        generated by the sample can be readily dissipated or
                        removed.
                      </td>
                      <td class="border px-4 py-2 font-medium"></td>
                      <td class="border px-4 py-2 font-medium">
                        SBAT standardized to utilize sample tubes that are
                        insulated to inhibit heat loss; insulated to at least a
                        temperature decay time constant (i.e. time to decay
                        36.8% of the initial temperature difference) of 10
                        minutes.
                      </td>
                    </tr>
                    <tr style="background:#f6f6f6;">
                      <td class="border px-4 py-2 font-medium">Confinement</td>
                      <td class="border px-4 py-2 font-medium">
                        Open configurations allow evaporation of volatiles and
                        pressure relief; sealed configurations resist loss of
                        volatiles and/or permit pressurization that can increase
                        the reaction rate.
                      </td>
                      <td class="border px-4 py-2 font-medium"></td>
                      <td class="border px-4 py-2 font-medium">
                        SBAT test tubes may be open or sealed; standardized
                        materials of construction is glass, but other materials
                        may be utilized as well.
                      </td>
                    </tr>
                    <tr>
                      <td class="border px-4 py-2 font-medium">Sample size</td>
                      <td class="border px-4 py-2 font-medium">
                        The larger the sample size the greater the resistance in
                        transferring heat from the center of the sample and thus
                        the more sensitive is the test.
                      </td>
                      <td class="border px-4 py-2 font-medium"></td>
                      <td class="border px-4 py-2 font-medium">
                        SBAT standardized to 3 – 5 grams
                      </td>
                    </tr>
                    <tr style="background:#f6f6f6;">
                      <td class="border px-4 py-2 font-medium">
                        Sample response to temperature profile
                      </td>
                      <td class="border px-4 py-2 font-medium">
                        Observation, detection, and documentation of a
                        material’s response to the test stimuli
                      </td>
                      <td class="border px-4 py-2 font-medium"></td>
                      <td class="border px-4 py-2 font-medium">
                        SBAT standardized to utilize 30-gauge thermocouples
                        (sensitive to small temperature changes); thermocouples
                        can be placed in the sample or on the outside of the
                        sample tube
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr style="background:#f6f6f6;">
                    <td class="border px-4 py-2 font-medium">
                      Temperature Increase
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      Thermocouple
                    </td>
                    <td class="border px-4 py-2 font-medium">
                      For a ramped test, the temperature at which the substance
                      begins to exotherm is the auto-ignition temperature. For
                      an isothermal test, if a temperature rise of 1.5°C over
                      the baseline is observed then the substance is not
                      thermally stable at that temperature.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="Example SBAT Results: Autoignitione"
              size="small"
            />
            <a
              :href="require('../../assets/IPSeries/HMXSBATplot-min.png')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/IPSeries/HMXSBATplot-min.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
